<template>
  <section :class="$customSectionClasses(props.cfgs)">
    <div
      v-if="!isHide"
      ref="refVisualChoiceFloorApartment"
      class="visual-choice-floor-apartment"
    >
      <div class="container">
        <div
          v-if="values.title"
          class="visual-choice-floor-apartment__title h2"
        >
          {{ values.title }}
        </div>

        <div
          class="visual-choice-floor-apartment__body"
          :class="{
            '--padding': Boolean(activeStage === 2),
            '--not-image': isNotImage
          }"
        >
          <ImageSvgStage
            v-if="getActiveCategory"
            :key="`active-stage-${ activeStage }`"
            :category="getActiveCategory"
            :apartment-data="apartmentData"
            :active-stage="activeStage"
            :value-type="values.type || 1"
            @changeStageNext="changeStageNext"
            @changeActiveEntrance="changeActiveEntrance"
          />

          <div
            v-if="Boolean(isVisiblePrevButton)"
            class="visual-choice-floor-apartment__navigations"
            @click="changeStagePrev"
          >
            <img
              alt="arrow left ico"
              src="~/assets/img/svg/common/arrow-left-slider.svg"
              class="opacity-60"
            >
            Назад
          </div>
          <div
            v-if="isLoad"
            class="visual-choice-floor-apartment__loading"
          >
            <img
              src="~/assets/img/svg/loading/body-loading.svg"
              alt="loading animation"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type {IBlockDefaultProps} from '#sitis/internal/models/common';
import type {SectionTypes} from '~/plugins/customSectionClasses';
import type {ICategory} from '#sitis/internal/controllers/categories/models/Category';
import {Category} from '#sitis/internal/controllers/categories/models/Category';

const ImageSvgStage = defineAsyncComponent(() => import('~/components/visual-choice-floor-apartment/ImageSvgStage.vue'));

export type ApartmentDataType = {
  category_id: number;
  floor_products_count: number;
  min_price: string;
  products_count: number;
  rooms: string;
  type: string;
}

type VisualChoiceFloorApartmentBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
  values: {
    category_id: number;
    sub_category_id: string;
    title?: string;
    type: number;
  };
  cfgs: SectionTypes;
}
const {$customSectionClasses, $api} = useNuxtApp();
const router = useRouter();
const $route = useRoute();
const props = defineProps<VisualChoiceFloorApartmentBlockType>();

const refVisualChoiceFloorApartment = ref<HTMLDivElement>();

const isHide = ref<boolean>(false);
const isLoad = ref<boolean>(true);

const activeStage = ref<number>(0);
const initialActiveStage = ref<number>(0);

const apartmentData = ref<Array<ApartmentDataType>>([]);
const selectedEntrance = ref<string>('');

// Главной категория с ее потомками
const rootCategory = ref<ICategory | null>();

const projectData = ref<ICategory | null>();
const houseData = ref<ICategory | null>();
const floorData = ref<ICategory | null>();

const getActiveCategory = computed(() => {
  switch (activeStage.value) {
    case 0:
      return projectData.value;

    case 1:
      return houseData.value;

    case 2:
      return floorData.value;
    default:
      return null;
  }
});

const isVisiblePrevButton = computed(() => Boolean(activeStage.value > initialActiveStage.value));

const isNotImage = computed(() => {
  if (activeStage.value === 0) {
    return !getActiveCategory?.value?.dynamicFields?.house_areas_image;
  }
  if (activeStage.value === 1) {
    return !getActiveCategory?.value?.dynamicFields?.floor_areas_image;
  }
  if (activeStage.value === 2) {
    return !getActiveCategory?.value?.imageId;
  }

  return false;
});

const _getCategoryStage2 = async (category: ICategory, categoryId: string | number) => {
  const type = props.values?.type || 1;

  if (category?.dynamicFields) {
    category.dynamicFields.children = await $api.agent
      .get(`/categories/${categoryId}/products?expand=short_characteristics&active=0&group=${type}`)
      .then((res) => res._data)
      .catch((e) => {
        console.log('get-category-stage-2 error:', errorParserServerResponse(e?.response ?? e));

        return [];
      });
  }

  return category;
};

// Сборка и парсинг этапов визуального выбора
const parserQueryStage = async () => {
  const query = $route.query || {};
  const queryHouseSlug = query.house
  const queryFloorSlug = query.floor
  const queryEntranceSlug = query.entrance

  const queryHouseValue = Array.isArray(queryHouseSlug) ? '' : queryHouseSlug
  const queryFloorValue = Array.isArray(queryFloorSlug) ? '' : queryFloorSlug
  const queryEntranceValue = Array.isArray(queryEntranceSlug) ? '' : queryEntranceSlug

  let stage = activeStage.value;
  let currentHouseData = houseData.value;
  let currentFloorData = floorData.value;

  const searchBySlug = (data: ICategory | null | undefined, searchKeyword: string) => {
    return (data?.dynamicFields.children || [])
      .map((t: any) => new Category(t).toJSON())
      .find((t: ICategory) => t.slug === searchKeyword);
  }

  const searchFloor = async (searchCategory: ICategory) => {
    if (searchCategory) {
      const resultFloor = await _getCategoryStage2(searchCategory, String(query.floor));

      if (resultFloor) {
        currentFloorData = resultFloor
        stage = 2;
      } else {
        return
      }
    } else {
      return
    }
  }


  if (queryHouseValue) {
    const resultHouse = searchBySlug(rootCategory.value, queryHouseValue)

    if (resultHouse) {
      currentHouseData = resultHouse ?? []
      stage = 1;

      if (queryEntranceValue && queryEntranceValue && currentHouseData?.dynamicFields?.is_how_many_floors) {
        const resultEntranceData = searchBySlug(currentHouseData, queryEntranceValue) ?? []
        const hasFloors = resultEntranceData.dynamicFields.children.length > 0

        if (queryFloorValue && hasFloors) {
            await searchFloor(searchBySlug(resultEntranceData, queryFloorValue))
        }
      } else {
        if (queryFloorValue) {
          await searchFloor(searchBySlug(currentHouseData, queryFloorValue))
        }
      }
    }
  }

  activeStage.value = stage;
  houseData.value = currentHouseData;
  floorData.value = currentFloorData;
  isLoad.value = false;
};

// Установка изначальной позиции скрола
const setInitScrollPage = () => {
  const searchParams = $route.query;
  if (!searchParams.house && !searchParams.floor) {
    return;
  }

  if (refVisualChoiceFloorApartment.value) {
    let scrollPositionTop = refVisualChoiceFloorApartment.value.getBoundingClientRect()?.top;
    scrollPositionTop += window.scrollY;
    scrollPositionTop -= 80;

    window.scrollTo({
      top: scrollPositionTop,
      behavior: 'smooth'
    });
  }
};

// Получение родительской категории с ее потомками
const getRootCategory = async () => {
  // Получение родитеской категории с ее потомками
  const categoryId = props.values.category_id;
  const action = `/categories/${categoryId}?expand=children,children.children,children.children.children`;

  const res = await $api.agent.get(action)
    .then((res) => res._data)
    .catch((e) => {
      console.log('get-root-category error:', errorParserServerResponse(e?.response ?? e));

      return null;
    });

  if (!res) {
    isHide.value = true;

    return;
  }
  const rootCategoryCurrent = new Category(res).toJSON();

  const rootCategoryChildren = (rootCategoryCurrent.dynamicFields?.children || [])
    .map((t: any) => new Category(t).toJSON())
    .filter((t: ICategory) => (t.dynamicFields?.children || []).length > 0);

  if (rootCategoryChildren.length <= 0) {
    isHide.value = true;

    return;
  }

  // Получение информации по квартрам от родителской категории
  const type = props.values?.type || 1;
  const apartmentDataCurrent = await $api.agent
    .get<ApartmentDataType[]>(`/plan/data/${categoryId}?filter[status][]=available&group=${type}`)
    .then((res) => res._data)
    .catch((e) => {
      console.log('get-apartment-data error:', errorParserServerResponse(e?.response ?? e));

      return [];
    });

  let activeStageCurrent = 0;
  const currentProjectData = rootCategoryCurrent;
  let currentHouseData = null;

  if (rootCategoryChildren.length <= 1) {
    activeStageCurrent = 1;
    currentHouseData = rootCategoryChildren?.[0];
  }
  if (props.values.sub_category_id) {
    const subCategory = (rootCategoryChildren || []).find((t: any) => t.id === props.values.sub_category_id);
    if (subCategory) {
      activeStageCurrent = 1;
      currentHouseData = subCategory;
    }
  }

  activeStage.value = activeStageCurrent;
  rootCategory.value = rootCategoryCurrent;
  apartmentData.value = apartmentDataCurrent || [];
  projectData.value = currentProjectData || null;
  houseData.value = currentHouseData || null;
  initialActiveStage.value = activeStageCurrent;

  if (Object.keys($route.query || {}).length > 0) {
    await parserQueryStage();

    return;
  }

  isLoad.value = false;
};

const changeStageNext = async (categoryId: number) => {
  const currentStage = activeStage.value;
  const nextStage = activeStage.value + 1;

  let currentCategory: ICategory | null | undefined = null;

  switch (currentStage) {
    case 0:
      currentCategory = projectData.value;
      break;
    case 1:
      currentCategory = houseData.value;
      break;
    case 2:
      currentCategory = floorData.value;
      break;
    default:
      break;
  }

  let nextCategory = (currentCategory?.dynamicFields?.children || [])
    .map((t: any) => new Category(t).toJSON())
    .find((t: ICategory) => {
      return Boolean(t.id === categoryId)
    });
  if (currentCategory?.dynamicFields.is_how_many_floors) {
    let fullCategories: any = [];
    (currentCategory?.dynamicFields?.children || []).map((child: any) => {
      fullCategories.push(...(child.children || []))
    });
    nextCategory = fullCategories.find((t: any) => t.id === categoryId);
    nextCategory = new Category(nextCategory);
  }

  if (nextStage === 2) {
    nextCategory = await _getCategoryStage2(nextCategory, categoryId);
  }

  switch (nextStage) {
    case 1:
      houseData.value = nextCategory;
      break;

    case 2:
      floorData.value = nextCategory;
      break;

    default:
      break;
  }

  activeStage.value = nextStage;

  setQueryStage();
};

const changeActiveEntrance = (entranceSlug: string) => {
  console.log('change-active')
  selectedEntrance.value = entranceSlug

  setQueryStage()
}

const changeStagePrev = () => {
  const currentStage = activeStage.value;
  const prevStage = activeStage.value - 1;

  switch (currentStage) {
    case 0:
      projectData.value = null;
      break;
    case 1:
      houseData.value = null;
      break;
    case 2:
      floorData.value = null;
      break;
    default:
      break;
  }

  activeStage.value = prevStage;

  setQueryStage();
};

const setQueryStage = () => {
  const stages = [];

  if (activeStage.value >= 1) {
    stages.push(`house=${houseData.value?.slug}`);

    if (houseData.value?.dynamicFields?.is_how_many_floors) {
      if (selectedEntrance.value === '') {
        selectedEntrance.value = houseData.value?.dynamicFields?.children?.[0]?.slug
      }

      stages.push(`entrance=${selectedEntrance?.value}`)
    }
  }

  if (activeStage.value >= 2) {
    stages.push(`floor=${floorData.value?.slug}`);
  }

  const stagesStr = stages.join('&');
  window.history.replaceState(null, '', `${window.location.pathname}?${stagesStr}`);
};

onMounted(async () => {
  await getRootCategory();
  setInitScrollPage();
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/media";

.visual-choice-floor-apartment {
}

.visual-choice-floor-apartment__title {
  margin-bottom: 40px;
}

.visual-choice-floor-apartment__homes {
  display: flex;
  align-items: center;
  margin-left: -4px;
  margin-top: -4px;
  margin-bottom: 40px;

  & > * {
    background: #FFF;
    border: 1px solid #E2DDE9;
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    margin-top: 4px;
    margin-left: 4px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #3C1D6D;

    &.active {
      border-color: #FFC115;
      background-color: #FFF3D0;
    }
  }
}

.visual-choice-floor-apartment__badge {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 2;
  padding: 20px 24px;
  background-color: #FFF;
  border-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #3C1D6D;
}

.visual-choice-floor-apartment__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  position: relative;
  height: 800px;
  background-color: #F5F4F8;

  &.--not-image {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.visual-choice-floor-apartment__navigations {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 100px;
  border: 1px solid #E2DDE9;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  color: #3C1D6D;

  img {
    width: 14px;
    margin-right: 8px;
  }

  &:hover {
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  }
}

.visual-choice-floor-apartment__loading {
  position: absolute;
  inset: 0;
  z-index: 10;
  background-color: rgb(255 255 255 / 80%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(12px);
  border-radius: 20px;

  img {
    width: 160px;
  }
}

@include media('md') {
	.visual-choice-floor-apartment__badge {
		padding: 8px 20px;
		top: 10px;
		left: 10px;
		font-size: 14px;
		line-height: 16px;
		border-radius: 4px;
	}

  .visual-choice-floor-apartment__body {
    min-height: 220px;
    border-radius: 8px;
  }

  .visual-choice-floor-apartment__loading {
    border-radius: 8px;
  }
}

@include media('sm') {
	.visual-choice-floor-apartment {
		display: none;
	}
}
</style>
